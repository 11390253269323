import React, { useEffect, useState } from 'react';
import Navbar from '../components/Navbar';
import NavbarMap from '../components/NavbarMap';
import NavbarClimb from '../components/NavbarClimb';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import './ClimbPage.css'
import locations from '../data/location-data.json';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';



function ClimbPage() {
  const { id } = useParams();
  // const [climbData, setClimbData] = useState(null);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  // useEffect(() => {
  //   axios.get(`api/locations/${id}`)
  //     .then(response => {
  //       console.log('API response:', response.data);
  //       setClimbData(response.data);
  //     })
  //     .catch(error => {
  //       console.error('Error fetching climb data:', error);
  //     });
  // }, [id]);

  const climbData = locations.locations.find(location => location.id === parseInt(id, 10));


  return (
    <div>
      <NavbarClimb />
      <div className='climb-page-area'>
        {climbData ? (
          <div className='img-data-box'>

            <img src={climbData.image_path} alt="iceClimbImg" />
            <p className='photo_credit'>Photo Credit: {climbData.photo_credit}</p>

            <div className='climb-data' style={{ whiteSpace: 'pre-line' }}>
              <h1 className='climb-title'> {climbData.name} </h1>
              <p>Access: {climbData.access}</p>
              <p >Description: {climbData.description}</p>
              <p>Area: {climbData.area}</p>
              <p>Nearest City: {climbData.nearest_city}</p>

              <div className='grade-modal'>
                <p>Grade: {climbData.grade}</p>
                <Button className='wi-grade-text' onClick={handleOpen}>What does this mean?</Button>
              </div>

              <Modal
                className='modal-box'
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={{ 
                  maxWidth: '70%', 
                  maxHeight: '96%',
                  fontSize: '10px',
                  padding: '10px',
                  backgroundColor: 'white', // Add a background color
                }}>
                  <Typography id="modal-modal-title" variant="h6" component="h2">
                    WI(Water Ice) Break Down
                  </Typography>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  <div onClick={handleClose}>
                    <p className='wi-grade'>
                     WI 2 - 
                     Short sections with angles up to 80 degrees
                     Good potential for protection in all areas
                   </p>
           
                   <p>
                     WI 3 -
                     Longer sections with angles up to 80 degrees
                     Potential to place good protection from rest areas
                   </p>
           
                   <p>
                     WI 4 -
                     Long sections with 80 degree ice
                     or shorter 10 - 25 m of vertical ice
                     Good potential for protection but in more difficult locations
                   </p>
           
                   <p>
                     WI 5 -
                     20 - 40 m sections of vertical ice
                     or shorter sections of very bad ice vertical ice
                     Marginal potential for protection
                   </p>
         
                   <p>
                     WI 6 -
                     Long 50 m sections of vertical ice
                     Very marginal potential for protection
                   </p>
         
                   <p>
                     WI 7 -
                     Long and overhanging
                     Minimal potential for protection if any
                   </p>
                 </div>
                  </Typography>
                </Box>
              </Modal>
                
              <p>Height: {climbData.height}m</p>
              <p>Top Rope: {climbData.top_rope}</p>
              <p>Type of access: {climbData.access_type}</p>
              <p>Lat: {climbData.latitude} Long: {climbData.longitude}</p>
              {/* <p>(if Lat & Long are N/A, no directions available.)</p>
              <a className='google-maps' href={`https://www.google.com/maps?q=${climbData.latitude},${climbData.longitude}`} target="_blank" rel="noopener noreferrer">
                View on Google Maps (Approximate)
              </a>
              <a className='google-maps' href={climbData.kmz_link} target="_blank" rel="noopener noreferrer">
                GPS track .kmz file
              </a>
              <p>(Google Earth is a free .kmz viewer)</p> */}
              {climbData.latitude !== 'N/A' ? (
                <div>
                  <a className='google-maps' href={`https://www.google.com/maps?q=${climbData.latitude},${climbData.longitude}`} target="_blank" rel="noopener noreferrer">
                    View on Google Maps (Approximate)
                  </a>
                  <a className='google-maps' href={climbData.kmz_link} target="_blank" rel="noopener noreferrer">
                    GPS track .kmz file
                  </a>
                  <p>(Google Earth is a free .kmz viewer)</p>
                </div>
              ) : (
                <p>No location information available.</p>
              )}
              <p>*Location Information could be outdated*</p>

            </div>

          </div>
        ) : (
          <p>Loading climb data...</p>
        )}

      </div>
    </div>
  );
}

export default ClimbPage;
