// import * as React from 'react';
// import SendIcon from '@mui/icons-material/Send';
// import Button from '@mui/material/Button';
import emailjs from '@emailjs/browser';
import React, { useState } from 'react';
import { Link } from "react-router-dom";
import './EmailForm.css'

function EmailForm() {
  const [MessageSent, setMessageSent] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    // alert("submitted");
    // window.location.reload();

    emailjs.sendForm(process.env.REACT_APP_EMAILJS_SERVICE_ID, process.env.REACT_APP_EMAILJS_TEMPLATE_ID, e.target, process.env.REACT_APP_EMAILJS_PUBLIC_KEY)

    .then(() => {
    //   // window.location.href = '/EmailSent';
      setMessageSent(true);
    })
    .catch((error) => {
      console.error('Error sending email:', error);
    });

  }


  return (

    <div className='email-form' >

      {!MessageSent ? (
        
          <form className="contact__form" onSubmit={sendEmail}>
            <div className='email-inputs'>
              <div className='email-form'>
                <label htmlFor="emailFrom">Your Email: </label>
                <input type="text" name="email_from" id="emailFrom" className="email__from"/>
              </div>
              <div className='email-form'>
                <label htmlFor="message">Message: </label>
                <textarea name="message" id="message" className="message__box"></textarea>
              </div>
              <div className='email-form'>
                <input className='send-button' type="submit" value="Submit" />
              </div>
            </div>
          </form>
        
      
      ) : (

        <p>Message Sent!</p>

      )}
      
    </div>
  );
}

export default EmailForm;