import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './MapStats.css'
import { Link } from 'react-router-dom';
import locations from '../data/location-data.json';




function MapStats() {
  const [locationCount, setLocationCount] = useState(null);
  const [locationGIS, setLocationGIS] = useState(null);
  const [locationImage, setLocationImage] = useState(null);


  useEffect(() => {
    // Total Location Count
    setLocationCount(locations.locations.length);

    // Total GIS Location Count
    const filteredLocations = locations.locations.filter(location => location.latitude !== 'N/A');
    setLocationGIS(filteredLocations.length);

    // Total Image Type Count
    const filteredImage = locations.locations.filter(location => location.image_path !== "https://i.imgur.com/EdsyHhL.jpg");
    setLocationImage(filteredImage.length);
  }, []);

  return (

    <div className='stats-area'>
    
      <div style={{ textAlign: 'center' }}>
        <h2 className='stats-text'>Currect IceClimbBC Stats</h2>
      </div>

      {locationCount !== null ? (
        
        // default behavior was linking to bottom of Map page for some reason
        <Link className='stats-link' to='/Map' onClick={() => window.scrollTo(0, 0)}>
          <div className='stats-box'>
  
            <div className='stat-count'>
              <p className='stat-description'>Ice Climbs added</p>
              <p className='number'>{locationCount}</p>
            </div>
  
            <div className="stat-count">
              <p className='stat-description'>GIS data added</p>
              <p className='number'>{locationGIS}</p>
            </div>
  
            <div className='stat-count'>
              <p className='stat-description'>Location Images added</p>
              <p className='number'>{locationImage}</p>
            </div>
          
          </div>
        </Link>

      ) : (
        <p>Loading...</p>
      )}

    </div>
    
    

  );
}

export default MapStats;
