import React from 'react'
import Navbar from '../components/Navbar'
import MyMap from '../components/MyMap';
import NavbarMap from '../components/NavbarMap'
import Footer from '../components/Footer';



export const Map = () => {
  return (
    <div map-area>
      <NavbarMap/>

      <MyMap />
      <Footer/>
    </div>
  )
}

export default Map;
