import React, { useState, useEffect } from "react";
import { Map, Marker, Overlay } from "pigeon-maps";
import './MyMap.css'
import { Link } from "react-router-dom";
import locations from '../data/location-data.json';


function MyMap() {
  const [allLocations, setAllLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [selectedGradeFilter, setSelectedGradeFilter] = useState([]);
  const [selectedHeightFilter, setSelectedHeightFilter] = useState('');
  const [selectedAreaFilter, setSelectedAreaFilter] = useState('');
  const [selectedCityFilter, setSelectedCityFilter] = useState('');
  const [uniqueAreas, setUniqueAreas] = useState([]);
  const [uniqueCities, setUniqueCities] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isMapExpanded, setIsMapExpanded] = useState(false);
  const [filteredAreas, setFilteredAreas] = useState([]);


  useEffect(() => {
    // Fetch locations from your backend API
    setAllLocations(locations.locations); // Store the location data in allLocations

    // Extract unique areas and cities from the location data
    const areas = [...new Set(locations.locations.map(location => location.area))];
    const cities = [...new Set(locations.locations.map(location => location.nearest_city))];
    setUniqueAreas(areas);
    setUniqueCities(cities);
    setFilteredAreas(areas);
  }, []);

  const imageCheck = (loc) => {
    if (loc.image_path !== "https://i.imgur.com/EdsyHhL.jpg") {
      return "Yes"
    } else {
      return "No"
    }
  };

  const gpsCheck = (loc) => {
    if (loc.kmz_link !== "N/A") {
      return "Yes"
    } else {
      return "No"
    }
  };
  
  const handleMapToggle = () => {
    console.log("Button Clicked");
    setIsMapExpanded(!isMapExpanded);
  };
  
  const handleMarkerClick = (location) => {
    console.log('marker clicked');
    setSelectedLocation(location);
  };  

  //function to find climb height range
  const heightInRange = (location, min, max) => {
    const height = parseFloat(location.height);
    return !isNaN(height) && height >= min && height <= max;
  };

  //Clear all filters
  const clearFilters = () => {
    setSelectedGradeFilter([]);
    setSelectedHeightFilter('');
    setSelectedAreaFilter('');
    setSelectedCityFilter('');
    setSearchTerm('');
    setUniqueAreas([])
    setUniqueCities([])
    setFilteredAreas([])
  };

  const filterLocations = (location) => {

    //Text search
    const searchFilterPass = !searchTerm || location.name.toLowerCase().includes(searchTerm.toLowerCase());

    //Grade filter
    const gradeFilterPass =
    selectedGradeFilter.length === 0 || selectedGradeFilter.includes(location.grade);

    //Nearest City filter
    const cityFilterPass = !selectedCityFilter || location.nearest_city.toString() === selectedCityFilter.toString();

    //Area filter
    const areaFilterPass = !selectedAreaFilter || location.area.toString() === selectedAreaFilter.toString();

    //Height Filter
    const heightFilterPass =
      !selectedHeightFilter ||
      (selectedHeightFilter === '0m-30m' && heightInRange(location, 0, 30)) ||
      (selectedHeightFilter === '31m-100m' && heightInRange(location, 31, 100)) || (selectedHeightFilter === '101m-300m' && heightInRange(location, 101, 300));
  
    return searchFilterPass && gradeFilterPass && heightFilterPass && areaFilterPass && cityFilterPass;
  };

  const filteredLocationsList = locations.locations.filter(filterLocations);

  const handleMarkerQuit = () => {
    console.log('Marker quit')
    setSelectedLocation(null)
  }

  const updateFilteredAreas = (selectedCity) => {
    if (selectedCity === "") {
      // If "All Cities" is selected, show all areas
      setFilteredAreas(uniqueAreas);
    } else {
      // Filter areas based on the selected city and store in the filteredAreas state
      const areasForSelectedCity = [...new Set(
        locations.locations
          .filter(location => location.nearest_city === selectedCity)
          .map(location => location.area)
      )];
      setFilteredAreas(areasForSelectedCity);
    }
  };
  
  return (
    <div className="map-container">

      

      {/* Filter Dropdowns */}
      <div className="filter-dropdowns">

        {/* Text Search */}
        <div className="text-search-container">
          <input
            className='text-search'
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        <div className='filters'>
          <div className='filter-batch'>
            {/* Nearest City filter */}
            <select
              value={selectedCityFilter}
              onChange={(e) => {
                setSelectedCityFilter(e.target.value);

                setSelectedAreaFilter("");
                // Call a function to update the filtered areas based on the selected city
                updateFilteredAreas(e.target.value);
              }}
            >
              <option value="">All Cities</option>
              {uniqueCities.map((city, index) => (
                <option key={index} value={city}>
                  {city}
                </option>
              ))}
            </select>
            
            {/* Area filter */}
            <select
              value={selectedAreaFilter}
              onChange={(e) => setSelectedAreaFilter(e.target.value)}
            >
              <option value="">All Areas</option>
              {filteredAreas.map((area, index) => (
                <option key={index} value={area}>
                  {area}
                </option>
              ))}
            </select>
          </div>
  
          <div className='filter-batch'>
            {/* Grade Filter */}
            <select
              value={selectedGradeFilter}
              onChange={(e) => setSelectedGradeFilter(e.target.value)}
            >
              <option value="">All Grades</option>
              <option value="WI-2-WI-3">WI-2 WI-3</option>
              <option value="WI-4-WI-5">WI-4 WI-5</option>
              <option value="WI-6-WI-7">WI-6 WI-7</option>
              
            </select>

  
            {/* Height Filter */}
            <select
              value={selectedHeightFilter}
              onChange={(e) => setSelectedHeightFilter(e.target.value)}
            >
              <option value="">All Heights</option>
              <option value="0m-30m">0m-30m</option>
              <option value="31m-100m">31m-100m</option>
              <option value="101m-300m">101m-300m</option>
            </select>
          </div>

        </div>

      </div>
      
      {/* Expand/Shrink Map button */}
      <div className="map-size-button-container">
        <button className='map-size-button' onClick={handleMapToggle}>
          {isMapExpanded ? "Hide Map" : "Expand Interactive Map"}
        </button>

        {/* <button className='clear-filter' onClick={clearFilters}>Clear Filters</button> */}
      </div>

      <div className='preview-and-map-area'>
   
        <div className='map-area'>
          <div className='map'>
            <Map
              className='map'
              height={isMapExpanded ? 350 : 0}
              defaultCenter={[53.916943, -122.749443]}
              defaultZoom={6}
            >
              {locations.locations.map(location => (
                filterLocations(location) && (
                  <Marker
                    key={location.id}
                    width={30}
                    anchor={[Number(location.latitude), Number(location.longitude)]}
                    onClick={() => handleMarkerClick(location)}
                  >

                  </Marker>
                )
              ))}
              
              {/* Overlay should be placed here, outside of the Marker loop */}
              {selectedLocation && (
                <Overlay
                  anchor={[Number(selectedLocation.latitude), Number(selectedLocation.longitude)]}
                  offset={[50, 20]}
                >

                  <div className="map-preview">
                    <Link
                      to={`/ClimbPage/${selectedLocation.id}`}
                    >
                      <div className='map-preview-name-img'>
                        <img src={selectedLocation.image_path} alt={selectedLocation.name} />
                        <p>{selectedLocation.name}</p>
                      </div>
                    </Link>
                    <button onClick= {handleMarkerQuit}> X </button>


                  </div>

                </Overlay>
              )}
            </Map>

          </div>

        </div>
        
      </div>

      {/* Location list at the bottom */}
      <div className="location-list">
        <ul>
          {filteredLocationsList
          .sort((a, b) => a.nearest_city.localeCompare(b.nearest_city))
          .map(location => (
            <li key={location.id}>
              <Link
                onClick={() => {window.scrollTo(0, 0);}}
                to={`/ClimbPage/${location.id}`}
              >
                <p>{location.name}</p>
                <p>{location.nearest_city} - {location.area}</p>
                <p>Grade: {location.grade}</p>
                <p>Height: {location.height}m</p>
                {/* <p className='data-hide'>Top Rope: {location.top_rope}</p> */}
                {/* <p className='data-hide'>Access: {location.access_type}</p> */}
                <p className='data-hide'>GPS Tracks: {gpsCheck(location)}</p>
                <p className='data-hide'>Photos: {imageCheck(location)}</p>
              </Link>
            </li>
          ))}
        </ul>
      </div>

    </div>
  );
}

export default MyMap;
