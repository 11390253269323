import React from 'react'
import { Link } from "react-router-dom";
import NightClimb from '../docs/night-climb.png';
import './MapSection.css'

function MapSection() {

    return (
      <div className='map-box'>

        <div>
          <Link
            className='link-area' to="/Map"
            onClick={() => {window.scrollTo(0, 0);}}
          >
            <img src={NightClimb} alt="snowy mountain" />
          
            <div className="bottom-left">Map</div>
          </Link>
        </div>
        <div className='map-box-text'>
          <h2>Interactive Map and filtering.</h2>

            <div className='map-box-button'>
              <a href="/Map">
                <p>Map</p>
              </a>
            </div>

            <p>
            Explore our interactive ice climbing database, offering comprehensive route descriptions, skill-level assessments, and safety insights. IceClimbBC is dedicated to continually expanding its resource and collecting valuable data. Most climb information at the moment is sourced from the "PG Area Ice Climbing Manual". If stated, check there for original descriptions and information"
            </p>
        </div>
      </div>
    )

}

export default MapSection