import React from 'react'
import './ContactSection.css'
import EmailForm from './EmailForm';

function ContactSection() {

    return (
        <div className='contact-box' id='contact-section'>

            <p>Have questions or suggestions? Want to share the latest climb information or update? Found a bug in the site? Feel free to reach out! - your feedback is important to improving this resource for everyone!</p>
  
          <EmailForm/>
      
        </div>

    )

}

export default ContactSection;
