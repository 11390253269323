import React from "react"
import Navbar from "../components/Navbar";

const PageNotFound = () => {
  return (
    <div>
      <Navbar />
      <h1>Your off the route!</h1>
    </div>
  )
};

export default PageNotFound;
