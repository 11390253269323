import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";
import BlankLogo from '../docs/iceclimbbc logo blank.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faBars} from '@fortawesome/free-solid-svg-icons';

const Navbar = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const btnRef = useRef();

  // Function to open the navigation sidebar
  const openNav = () => {
    setIsNavOpen(true);
  };

  // Function to close the navigation sidebar
  const closeNav = () => {
    setIsNavOpen(false);
  };
  
  return (
    <nav>
      <div className="navbar">

        <img href='/' src={BlankLogo} className="navbar-logo" alt="logo" />

        <div id="mySidenav" className={`sidenav ${isNavOpen ? "open" : ""}`}>
          <a className="closebtn" onClick={closeNav}>&times;</a>
          <a href="/">Home</a>
          <a href="/#about-section" onClick={closeNav} >About</a>
          <a href="/#contact-section" onClick={closeNav}>Contact</a>
          <a href="/Map">Map</a>
          {/* Transparent overlay */}
          {isNavOpen && <div className="overlay" onClick={closeNav}></div>}
        </div>
        <div className='mobile-nav'>
          <button className='bars-button' ref={btnRef} onClick={openNav}>
            <FontAwesomeIcon icon={faBars} style={{ color: '#ffffff' }} />
          </button>
          
        </div>
        <ul className="navbar-links">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <a href="/#about-section">About</a>
          </li>
          <li>
            <a href="/#contact-section">Contact</a>
          </li>
          <li>
            <Link to="/Map">Map/Locations</Link>
          </li>
        </ul>

      </div>
    </nav>
  );
};

export default Navbar;
