import React from 'react'
import './SafetySection.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudSunRain } from '@fortawesome/free-solid-svg-icons';
import {faHillAvalanche} from '@fortawesome/free-solid-svg-icons';

function SafetySection() {

    return (
      <div className='safety-box'>
          <div className="safety-box-text">
            <p>
              Ice climbing comes with inherent risks. Safety should always be your top priority. Ensure you have the proper skills, equipment, and knowledge to tackle the ice. Check weather conditions, avalanche risks, and local regulations. Location information could be outdated, please take caution when using this resource.
            </p>

            <div className='safety-links'>

              <a href='https://www.avalanche.ca/' target='_blank' rel='noopener noreferrer'>
                <FontAwesomeIcon className='safety-icon' icon={faHillAvalanche} style={{ color: "#ffffff" }} />
                Avalanche Info
              </a>
              
              <a href='https://weather.gc.ca/city/pages/bc-79_metric_e.html' target='_blank' rel='noopener noreferrer'>
                <FontAwesomeIcon className='safety-icon' icon={faCloudSunRain} style={{ color: "#ffffff" }} />
                Weather Info
              </a>

            </div>

          </div>
        </div>
    )

}

export default SafetySection;
