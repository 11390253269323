import React from 'react';
import { Link } from "react-router-dom";
import Navbar from "../components/Navbar";
import MapStats from '../components/MapStats';
import Footer from "../components/Footer";
import SafetyNotice from '../components/SafteySection';
import MapSection from '../components/MapSection';
import AboutSection from '../components/AboutSection';
import ContactSection from '../components/ContactSection';
import './Home.css';

// Images import
import RedJacketClimber from '../docs/red-jacket-climber.jpg';



function Home() {


  return (
    <div className='home-area'>

      <Navbar className='Navbar' />

      <div className='page-body'>

        <div className='header-img-area'>
        
          <img className='banner-img' src={RedJacketClimber} alt="headerImage"/>
    
          {/* breaker to let all other content sit below the header image */}
          {/* <div className='invisible-breaker'/> */}

        </div>
        
        <div className='main-content'>
          <SafetyNotice/>
          
          <MapSection/>
  
          <MapStats/>
  
          <AboutSection/>
  
          <ContactSection/>
        </div>

      </div>

      <Footer/>
    </div>
  );
}

export default Home;
