import React from "react";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ReactDOM from "react-dom";
import Home from "./pages/Home";
import Map from './pages/Map';
import ClimbPage from "./pages/ClimbPage";
import PageNotFound from './pages/PageNotFound';
// import EditLocations from './pages/EditLocations'
// import UpdateData from "./pages/UpdateData";
// import EmailSent from "./pages/EmailSent";
import './Global.css'


export default function Index() {
  return (
  
    <Routes>
      
      <Route path="/" element={<Home />} exact />
      <Route path="/Map" element={<Map />} exact />
      <Route path="/ClimbPage/:id" element={<ClimbPage />} exact />
      <Route path="*" element={<PageNotFound />} />
      {/* <Route path="/EditLocations" element={<EditLocations />} />
      <Route path="/UpdateData/:id" element={<UpdateData />} /> */}
      
    </Routes>

  );
}