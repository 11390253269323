import React from "react";
import { Link } from "react-router-dom";
import BlankLogo from '../docs/iceclimbbc logo blank.png';
import BlankLogoWhite from '../docs/iceclimbbc-logo-blank-white.png';
import './NavbarMap.css'

const Navbar = () => {

  return (
    <nav>
      <div className="navbar-map">

        <div>
          <Link to="/">
            <img src={BlankLogoWhite} className="navbar-alt-logo" alt="logo" />
          </Link>
        </div>

        <div>
          <ul className="navbar-links-map">
            <li>
              <Link className='back-button' to="/Map">Back</Link>
            </li>
          </ul>
        </div>
        
      </div>
    </nav>
  );
};

export default Navbar;
