import React from 'react'
import './AboutSection'
import SnowMountain from '../docs/snow-mountain.jpg';
import './AboutSection.css'

function AboutSection() {

    return (
      <div className='about-box' id='about-section'>

      <div>
        <img src={SnowMountain} alt="snowy mountain" />
      </div>

      <div className='about-box-text'>
        <h2>About IceClimbBC.</h2>

          <p>Created by locals who are passionate about ice climbing and the outdoors of British Columbia. Our goal is to provide climbers with essential information to prepare for their next climb. While our resource is a reflection of our initial vision, we're dedicated to continuous improvement and welcome your suggestions. 
          <br/>
          <br/>
          We owe a big debt of gratitude to the PG Area Ice Climbing Manual and the members and friends of the Prince George Section of the ACC who assembled it's data. The mountain of work the original authors put into assembeling that resource has been instrumental in getting projects such as this off the ground.</p>
      </div>

    </div>
    )

}

export default AboutSection;
