import React from 'react';
import "./Footer.css";
import BlankLogo from '../docs/iceclimbbc logo blank.png';
import BlankLogoWhite from '../docs/iceclimbbc-logo-blank-white.png';

const Footer = () => {
  return (
    <div className='footer'>

      <div className='logo-area'>
        <img src={BlankLogoWhite} className="logo" alt="logo" />
      </div>
      
      <div className='resources'>

        <h2>Other Resources</h2>

        <ul>
          <li>
            <a href="https://drive.google.com/file/d/1TBR8eTSCFxGVl0Sik9sJMeb7N9fYCv9r/view" target='_blank' rel='noopener noreferrer'>PG Area Ice Climbing Manual</a>
          </li>
          <li>
            <a href="https://www.accprincegeorge.ca/Home" target='_blank' rel='noopener noreferrer'>accprincegeorge.ca</a>
          </li>
          <li>
            <a href="https://rockclimbpg.ca/" target='_blank' rel='noopener noreferrer'>rockclimbpg.ca</a>
          </li>
          <li>
            <a href="https://www.pgbrs.org/" target='_blank' rel='noopener noreferrer'>pgbrs.org</a>
          </li>
          <li>
            <a href="https://docs.google.com/spreadsheets/d/1fqM7wpBmyLIXfj0x_eQd0aGPRbAWMPBjIY3F-1FSR0g/edit?usp=sharing" target='_blank' rel='noopener noreferrer'>TBD location media</a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Footer;
